import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

const IndexPage = () => (
  <div>
    <Helmet>
      <meta charSet="UTF-8" />
      <title>Míriam Castellón — UX/UI Game Designer in Barcelona</title>
      <link rel="canonical" href="https://www.miriamcastellon.me/" />
    </Helmet>

    <h1>Hi people, it's me!</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <Link to="/page2/">Go to page 2</Link>
  </div>
)

export default IndexPage
